import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { env } from './const';
import { setUserSession } from '../utils/common';
import { useNavigate } from 'react-router-dom';



const Login = ({onLogin}) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onChange'
    });
    console.log("Login component is rendering");
    const navigate = useNavigate();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onSubmit = data => {
        // axios.post(`${env.produrl}/login`, data)
        //     .then(res => {
        //         if (res.status === 200 && res.data.userDetails[0].userType === 'Admin') {
        //             setUserSession(res.data.token, res.data.userDetails[0], res.data.browserDetails);
        //             props.history.push('/indus');
        //         } else {
        //             alert('Invalid Login');
        //         }
        //     }).catch(err => {
        //         setShowErrorMessage(true);
        //         setErrorMessage(err.response?.data.message || 'An error occurred');
        //     });
        // Simulating login session for testing without real API call
        if (data.username === 'AdminVector' && data.password === 'AdminVectorPassword') {
            // Simulate API response with token and user details
            const token = 'dummyToken';
            const user = { username: 'AdminVector', role: 'Admin' };
            const browserDetails = { browser: 'Chrome' };

            // Call the onLogin handler to set the authentication state and session
            onLogin(token, user, browserDetails);

            navigate('/dashboard'); // This replaces props.history.push
            console.log("Navigating to /dashboard");
        } else {
            setShowErrorMessage(true);
            setErrorMessage('Invalid Login');
        }
    };

    return (
        // <div className="login-page">
        //     <div className="login-box">
        //         <div className="login-logo">
        //             <img src='dist/img/logo-big.png' width='50%' alt="Poultry Pal" />
        //         </div>
        //         <div className="card">
        //             <div className="card-body login-card-body">
        //                 <p className="login-box-msg">Sign in to start your session</p>
        //                 {showErrorMessage && <center><span className="err-msg">{errorMessage}</span></center>}
        //                 <form onSubmit={handleSubmit(onSubmit)}>
        //                     <div className="input-group mb-3">
        //                         <input
        //                             type="text"
        //                             className="form-control"
        //                             placeholder="User ID"
        //                             {...register('username', {
        //                                 required: 'Please enter User ID'
        //                             })}
        //                         />
        //                         <div className="input-group-append">
        //                             <div className="input-group-text">
        //                                 <span className="fa fa-user-circle" />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {errors.username && <span className="err-msg">{errors.username.message}</span>}

        //                     <div className="input-group mb-3">
        //                         <input
        //                             type="password"
        //                             className="form-control"
        //                             placeholder="Password"
        //                             {...register('password', {
        //                                 required: 'Please enter Password'
        //                             })}
        //                         />
        //                         <div className="input-group-append">
        //                             <div className="input-group-text">
        //                                 <span className="fas fa-lock" />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {errors.password && <span className="err-msg">{errors.password.message}</span>}

        //                     <div className="row">
        //                         <div className="col-12">
        //                             <button type="submit" className="btn btn-primary btn-block">Log In</button>
        //                         </div>
        //                     </div>
        //                 </form>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <section className="h-200 gradient-form" style={{ backgroundColor: '#eee' }}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-xl-10">
                        <div className="card rounded-3 text-black">
                            <div className="row g-0">
                            <div className="col-lg-6 d-flex align-items-center">
                                    <img
                                        src={'https://neoaura.com/assets/img/NeoVector/NeoIndus5.jpg'}
                                        alt="Login Side"
                                        className="img-fluid w-100 rounded-start"
                                        style={{ objectFit: 'cover', height: '100%' }}
                                    />
                                </div>
                                <div className="col-lg-6 d-flex align-items-center">
                                    <div className="card-body p-md-5 mx-md-4">

                                        <div className="text-center">
                                            <img src='dist/img/logo-big.png' style={{ width: '185px' }} alt="Poultry Pal" />
                                            <h4 className="mt-1 mb-5 pb-1">We are The Vector Team</h4>
                                        </div>

                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <p>Please login to your account</p>

                                            <div className="form-outline mb-4">
                                                <input
                                                    type="text"
                                                    id="username"
                                                    className="form-control"
                                                    placeholder="User ID"
                                                    {...register('username', {
                                                        required: 'Please enter User ID'
                                                    })}
                                                />
                                                {/* <label className="form-label" htmlFor="username">Username</label> */}
                                                {errors.username && <span className="err-msg">{errors.username.message}</span>}
                                            </div>

                                            <div className="form-outline mb-4">
                                                <input
                                                    type="password"
                                                    id="password"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    {...register('password', {
                                                        required: 'Please enter Password'
                                                    })}
                                                />
                                                {/* <label className="form-label" htmlFor="password">Password</label> */}
                                                {errors.password && <span className="err-msg">{errors.password.message}</span>}
                                            </div>

                                            {showErrorMessage && <center><span className="err-msg" style={{fontSize:'large',marginTop:'10px'}}>{errorMessage} crediantials</span></center>}

                                            <div className="text-center pt-1 mb-5 pb-1">
                                                <button type="submit" className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3">
                                                    Log in
                                                </button>
                                                {/* <a className="text-muted" href="#!">Forgot password?</a> */}
                                            </div>

                                            {/* <div className="d-flex align-items-center justify-content-center pb-4">
                                                <p className="mb-0 me-2">Don't have an account?</p>
                                                <button type="button" className="btn btn-outline-danger">Create new</button>
                                            </div> */}
                                        </form>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                                    <div className="text-grey px-3 py-4 p-md-5 mx-md-4">
                                        <h4 className="mb-4">We are more than just a company</h4>
                                        <p className="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
