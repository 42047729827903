import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/pages/Login';
import TestScreen from './components/pages/TestScreen';
import Indus from './components/pages/Indus';
import TopHeader from '../src/components/layout/TopHeader';
import TopFooter from './components/layout/TopFooter';
import BatchOperations from './components/pages/BatchOperations';
import Users from './components/pages/Listofusers';
import ThresholdSettings from './components/pages/ThresholdSettings';
import { useEffect,useState } from 'react';
import { isAuthenticated, setUserSession, removeUserSession } from './components/utils/common'; // Import the utility functions
import ReadingByDate from './components/pages/ReadingsByDate';
import DeviceMaster from './components/pages/DeviceMaster';
import AbnormalReadings from './components/pages/AbnormalReadings';
import Analytics from './components/pages/Analytics';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the user is authenticated
    setAuthenticated(isAuthenticated());
  }, []);

  const handleLogin = (token, user, browserDetails) => {
    setUserSession(token, user, browserDetails); // Set the session in localStorage
    setAuthenticated(true); // Update the state
  };
  return (
    // <Router>
    //   <div className="App">
    //     <Routes>
    //     <Route path="/" element={<Login />} />
    //       <Route path="/login" element={<Login />} />
    //       <Route path="/test" element={<TestScreen />} /> {/* Route for TestScreen */}
    //       <div class="wrapper">
    //         <TopHeader />
    //         <div className="content myappcontent">
    //           <div className="container-fluid">
    //             <Route exact path="/indus" element={<Indus/>}/>
    //             <Route exact path="/dashboard" component={Dashboard}></Route>
    //             <Route exact path="/batchOperations" component={BatchOperations}></Route>
    //             <Route exact path="/users" component={Users}></Route>
    //             <Route exact path="/deviceConnections" component={DeviceConnections}></Route>
    //             <Route exact path="/abnormalReadings" component={AbnormalReadings}></Route>
    //             <Route exact path="/thresholdSettings" component={ThresholdSettings}></Route>
    //             <Route exact path="/deviceMaster" component={DeviceMaster}></Route>
    //             <Route exact path="/farms" component={Farms}></Route>
    //           </div>
    //         </div>
    //         <TopFooter />
    //       </div>
    //     </Routes>
    //   </div>
    // </Router>
  //   <Router>
  //   <div className="App">
  //   <div class="wrapper">
  //   {authenticated && <TopHeader setAuthenticated={setAuthenticated}/>}
  //     <div className="content myappcontent">
  //       <div className="container-fluid">
  //       <Routes>
  //             <Route path="/" element={<Login onLogin={handleLogin} />} />
  //             <Route path="/login" element={<Login onLogin={handleLogin} />} />

  //             {authenticated ? (
  //               <>
  //                 <Route path="/test" element={<TestScreen />} />
  //                 <Route path="/indus" element={<Indus />} />
  //                 {/* <Route path='/batchOperations' element={<BatchOperations/>}/> */}
                  
  //                 <Route exact path="/users" element={<Users/>}></Route>
  //                 <Route exact path="/thresholdSettings" element={<ThresholdSettings/>}></Route>
  //                 <Route exact path="/readingsByDate" element={<ReadingByDate/>}></Route>
  //                 <Route exact path="/deviceMaster" element={<DeviceMaster/>}></Route>
  //                 <Route exact path="/abnormalReadings" element={<AbnormalReadings/>}></Route>
  //                 <Route exact path="/analytics" element={<Analytics/>}></Route>
  //               </>
  //             ) : (
  //               <Route path="*" element={<Login onLogin={handleLogin} />} />
  //             )}
  //           </Routes>
  //       </div>
  //     </div>
  //     {authenticated && <TopFooter />}
  //     </div>
  //   </div>
  // </Router>
  <Router>
  <div className="App">
    {authenticated && <TopHeader setAuthenticated={setAuthenticated} />}
    <div className="content myappcontent">
      <div className="container-fluid">
        <Routes>
          <Route path="/" element={<Login onLogin={handleLogin} />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          {authenticated ? (
            <>
              <Route path="/test" element={<TestScreen />} />
              <Route path="/dashboard" element={<Indus />} />
              {/* <Route path='/batchOperations' element={<BatchOperations/>}/> */}
              <Route path="/users" element={<Users />} />
              <Route path="/thresholdSettings" element={<ThresholdSettings />} />
              <Route path="/readingsByDate" element={<ReadingByDate />} />
              <Route path="/deviceMaster" element={<DeviceMaster />} />
              <Route path="/abnormalReadings" element={<AbnormalReadings />} />
              <Route path="/analytics" element={<Analytics />} />
            </>
          ) : (
            <Route path="*" element={<Login onLogin={handleLogin} />} />
          )}
        </Routes>
      </div>
    </div>
    {authenticated && <TopFooter />}
  </div>
</Router>

  );
}

export default App;
