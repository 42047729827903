import axios from "axios";
import React, { useState, useEffect } from "react";
import { env } from './const';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
// import { headers } from '../utils/common';
import moment from "moment/moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DataTable from "react-data-table-component";
import { AiOutlinePoweroff } from "react-icons/ai";
import { AiOutlineWarning } from "react-icons/ai";
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineBulb } from "react-icons/ai";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { Tab, Tabs } from 'react-bootstrap';

const Analytics = ()=>{
    return(
        <div className="card">
        <div className="card-body">
        <div style={{alignItems:'center',justifyContent:'center',fontWeight:'bold',fontSize:'20px'}}>Powerful analytics are loading</div>
        </div>
    </div>
    )
};

export default Analytics;