import axios from "axios";
import React, { useState, useEffect } from "react";
import { env } from './const';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import { headers } from '../utils/common';
import moment from "moment/moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DataTable from "react-data-table-component";
const AbnormalReadings = () => {
    // const { containerProps, indicatorEl } = useLoading({
    //     loading: true,
    //     indicator: <Bars width="30" color="#333" />,
    // });
    // const [headersobj] = useState(headers());
    // const [showLoader, setShowloader] = useState(false);
    // const [userData, setUserData] = useState([]);
    
    // const fetchUsers = () => {
    //     setShowloader(true)
    //     axios.get(env.produrl + '/users/list/', { headers: headersobj }
    //     ).then(res => {
    //         if (!res.data.status) {
    //             alert(res.data.message);
    //             return;
    //         } 
    //         setProgress(false);
    //         setUserData(res.data.data);
    //         setShowloader(false);
    //     }).catch((err) => {
    //         alert(err.message)
    //         console.log(err);
    //         setUserData([]);
    //         setShowloader(false);
    //     });
    // }
    // const [progress, setProgress] = useState(true);
    // const columns = [
    //     {
    //       name: "Name",
    //       selector: "name",
    //       sortable: true,
    //       width: '200px',
    //       cell: row => <div>{row.name}</div>,
    //     },
    //     {
    //       name: "Email",
    //       selector: "email",
    //       width: '250px',
    //       sortable: true,
    //       cell: row => <div>{row.email}</div>,
    //     },
    //     {
    //         name: "Phone Number",
    //         selector: "phoneNumber",
    //         width: '130px',
    //         sortable: true,
    //         cell: row => <div>{row.phoneNumber}</div>,
            
    //       },
    //     {
    //         name: "City",
    //         selector: "city",
    //         sortable: true,
    //         width: '150px',
    //         cell: row => <div>{row.city}</div>,
    //     },
    //     {
    //         name: "State",
    //         selector: "state",
    //         width: '130px',
    //         sortable: true,
    //         cell: row => <div>{row.state}</div>,
    //     },
    //     {
    //         name: "Status",
    //         selector: "status",
    //         width: '80px',
    //         sortable: true,
    //         cell: row => <div>{row.status}</div>,
    //     },
    //     {
    //         name: "Created Date",
    //         selector: "createdDate",
    //         sortable: true,
    //         width: '120px',
    //         cell: row => <div>{moment(row.createdDate).utcOffset(330).format('DD-MMM-YYYY')} </div>,
    //     },
    //     // {
    //     //     name: "Created By",
    //     //     selector: "createdBy",
    //     //     sortable: true,
    //     //     width: '120px',
    //     //     cell: row => <div>{row.createdBy}</div>,
    //     // }
    //   ];
    // useEffect(() => {
    //     fetchUsers();
    // }, []);

    return (
        // <div className="Abnormal">
        //     <div className="row">
        //         <div className="col-md-12 pull-right">
        //             <ul className="headerTitle">
        //                 <li><h3>Abnormal Readings</h3></li>
        //                 <li>
        //                     { showLoader?
        //                         <section {...containerProps}  style = {{"margin-top": "0px"}}>
        //                             {indicatorEl}
        //                         </section> :""
        //                     }
        //                 </li>
        //             </ul>
        //             <div className="card">
        //                 <div className="card-body">
        //                     {/* <DataTable
        //                         progressPending={progress}
        //                         columns={columns}
        //                         data={userData}
        //                         defaultSortAsc = {false}
        //                         pagination
        //                         dense='false'
        //                         compact
        //                         highlightOnHover = 'true'
        //                         striped
        //                         paginationPerPage = {15}
        //                         paginationRowsPerPageOptions = {[10, 25, 50, 100]}
        //                     /> */}
                        
        //                     {/* Card Body Ends */}
        //                 </div>

        //             </div>

        //         </div>

        //     </div>
        // </div>
        <div className="card">
            <div className="card-body">
            <div style={{alignItems:'center',justifyContent:'center',fontWeight:'bold',fontSize:'20px'}}>Under Development</div>
            </div>
        </div>
       
    );
};
export default AbnormalReadings;
