import axios from "axios";
import React, { useState, useEffect } from "react";
import { env } from './const';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import { headers } from '../utils/common';
import moment from "moment/moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DataTable from "react-data-table-component";
const DeviceMaster = () => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />,
    });
    const [headersobj] = useState(headers());
    const [showLoader, setShowloader] = useState(false);
    const [userData, setUserData] = useState([]);
    
    const fetchUsers = () => {
        setShowloader(true)
        axios.get(env.produrl + '/users/deviceData/', { headers: headersobj }
        ).then(res => {
            if (!res.data.status) {
                alert(res.data.message);
                return;
            } 
            setProgress(false);
            setUserData(res.data.data);
            setShowloader(false);
        }).catch((err) => {
            alert(err.message)
            console.log(err);
            setUserData([]);
            setShowloader(false);
        });
    }
    const [progress, setProgress] = useState(true);
    
    const columns = [
        {
          name: "device Name",
          selector: "deviceName",
          sortable: true,
          width: '200px',
          cell: row => <div>{row.deviceName}</div>,
          sortFunction: (a, b) => a.deviceName.toLowerCase().localeCompare(b.deviceName.toLowerCase())
        },
        {
          name: "device ID",
          selector: "deviceID",
          width: '150px',
          sortable: false,
          cell: row => <div>{row.deviceID}</div>,
        },
        {
            name: "farm Name ",
            selector: "farmName",
            width: '130px',
            sortable: true,
            cell: row => <div>{row.farmName}</div>,
            sortFunction: (a, b) => a.farmName.toLowerCase().localeCompare(b.farmName.toLowerCase(), undefined, { numeric: false, sensitivity: 'base', ignorePunctuation: true })
            
          },
          {
            name: "Farmer Name ",
            selector: "farmerName",
            width: '180px',
            sortable: true,
            cell: row => <div>{row.farmerName}</div>,
            sortFunction: (a, b) => a.farmerName.localeCompare(b.farmerName, undefined, { sensitivity: 'base' })
          },
          {
            name: "Farmer PhoneNo. ",
            selector: "farmerPhoneNumber",
            width: '150px',
            sortable: true,
            cell: row => <div>{row.farmerPhoneNumber}</div>,
            
          },
        {
            name: "location",
            selector: "location",
            sortable: false,
            width: '150px',
            cell: row => <div>{row.location}</div>,
        },
        {
            name: "modified Date",
            selector: "modifiedDate",
            width: '180px',
            sortable: true,
            cell: row => <div>{moment(row.modifiedDate).utcOffset(330).format('DD-MMM-YYYY')}</div>,
            sortFunction: (a, b) => new Date(a.modifiedDate) - new Date(b.modifiedDate)
        },
        {
            name: "Status",
            selector: "status",
            width: '180px',
            sortable: false,
            cell: row => <div>{row.status}</div>,
        },
        {
            name: "Created Date",
            selector: "createdDate",
            sortable: true,
            width: '220px',
            cell: row => <div>{moment(row.createdDate).utcOffset(330).format('DD-MMM-YYYY')} </div>,
            sortFunction: (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
        },
        // {
        //     name: "Created By",
        //     selector: "createdBy",
        //     sortable: true,
        //     width: '120px',
        //     cell: row => <div>{row.createdBy}</div>,
        // }
      ];
    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <div className="Users">
            <div className="row">
                <div className="col-md-12 pull-right">
                    <ul className="headerTitle">
                        <li><h3>Device Master</h3></li>
                        <li>
                            { showLoader?
                                <section {...containerProps}  style = {{"margin-top": "0px"}}>
                                    {indicatorEl}
                                </section> :""
                            }
                        </li>
                    </ul>
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                progressPending={progress}
                                columns={columns}
                                data={userData}
                                defaultSortAsc = {false}
                                pagination
                                dense='false'
                                compact
                                highlightOnHover = 'true'
                                striped
                                paginationPerPage = {15}
                                paginationRowsPerPageOptions = {[15, 25, 50, 100]}
                            />
                        
                            {/* Card Body Ends */}
                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
};
export default DeviceMaster;
