import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLoading, Bars } from '@agney/react-loading';
import { env } from './const';
import axios from "axios";
import { headers } from '../utils/common';
import 'react-calendar/dist/Calendar.css';
import DataTable from "react-data-table-component";
import moment from "moment";
import { Modal, Button } from 'react-bootstrap';
import AbnormalReadingsModal from './AbnormalReadingsModal';
import { AiOutlineReload } from "react-icons/ai";

const Comissioned = (props) => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />,
    });
    const [headersobj] = useState(headers());
    const [showLoader, setShowloader] = useState(false);

    const [connectionData, setConnectionData] = useState([]);
    
    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: false,
            width: '70px',
            cell: (row, index) => index + 1
        },
        {
            name: "IMEI Number",
            selector: "imeiNumber",
            width: '200px',
            sortable: false,
            cell: row => <div style={{ cursor: 'pointer', color: 'blue' }} 
            // onClick={() => clickHandler(row)}
            >
                {row.imeiNumber}</div>,
           
        },
        {
            name: "Device Name",
            selector: "deviceName",
            width: '250px',
            sortable: true,
            cell: row => <div>{row.deviceName}</div>,
            sortFunction: (a, b) => a.deviceName.localeCompare(b.deviceName, undefined, { sensitivity: 'base' })
        },
        {
            name: "Signal Strength",
            selector: "signalStrength",
            sortable: false,
            cell: row => <div><b>{row.signalStrength}</b></div>,
        },
        // {
        //     name: "Status",
        //     selector: "connectionStatus",
        //     width: '120px',
        //     sortable: true,
        //     cell: row => <div>{row.connectionStatus}</div>,
        // },
        {
            name: "Last updated at",
            selector: "lastUpdatedDate",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.lastUpdatedDate}</div>,
            sortFunction: (a, b) => new Date(a.lastUpdatedDate) - new Date(b.lastUpdatedDate)
        },
        {
            name: "Installed Location",
            selector: "currentLocation",
            width: '200px',
            sortable: false,
            cell: row => <div>{row.currentLocation}</div>,
        },
    ];
    const decommissionedColumns = [
        { name: '#', selector: row => row.index, sortable: true, width: '70px', cell: (row, index) => index + 1 },
        { name: "IMEI Number", selector: "imeiNumber", width: '200px',cell:row => <div>{row.imeiNumber}</div>, sortable: false, },
        { name: "Device Name", selector: "deviceName", width: '250px',cell:row => <div>{row.deviceName}</div>, sortable: true,sortFunction: (a, b) => a.deviceName.localeCompare(b.deviceName, undefined, { sensitivity: 'base' }) },
        { name: "Batch Name", selector: "batchName", width: '250px',cell:row => <div>{row.batchName}</div>, sortable: true,sortFunction: (a, b) => a.batchName.localeCompare(b.batchName, undefined, { sensitivity: 'base' }) },
        { name: "Batch Start Date", selector: "batchStartDate", width: '200px', sortable: true, cell: row => moment(row.batchStartDate).format('YYYY-MM-DD'),sortFunction: (a, b) => a.batchStartDate.localeCompare(b.batchStartDate) },
        { name: "Batch End Date", selector: "batchEndDate", width: '200px', sortable: true, cell: row => row.batchEndDate ? moment(row.batchEndDate).format('YYYY-MM-DD') : '-',sortFunction: (a, b) => a.batchEndDate.localeCompare(b.batchEndDate) },
        
    ];
    const getColumns = () => {
        if (props.action === 'CLOSED') {
            return decommissionedColumns;
        } else {
            return columns;
        }
    };


    // Function to fetch device data based on "ACTIVE" or "CLOSED"
    const connections = () => {
        setShowloader(true);

        axios.get(`${env.produrl}/admin/deviceStatus/${props.action}`, { headers: headersobj })
            .then(res => {
                if (!res.data.status) {
                    alert(res.data.message);
                    return;
                }
                setConnectionData(res.data.list); // Store fetched data
                setShowloader(false);
            }).catch((err) => {
                alert(err.message);
                setShowloader(false);
            });
    };

    // Fetch the device data when the component mounts or when the action changes
    useEffect(() => {
        connections();
    }, [props.action]);  // Add props.action as a dependency

    return (
        <div className="dashboard">
            {/* Table rendering */}
            <div className="row">
                <div className="col-md-12">
                    <DataTable
                        progressPending={showLoader}
                        columns={getColumns()}
                        data={connectionData}
                        pagination
                        dense='false'
                        highlightOnHover='true'
                        striped
                        paginationPerPage={25}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
                </div>
            </div>
        </div>
    );
};

export default Comissioned;