import React from "react";
import { NavLink } from "react-router-dom";
import { removeUserSession } from '../utils/common';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
const TopHeader = ({ setAuthenticated }) => {
  const navigate = useNavigate();
  const logout = () => {
    removeUserSession();
    setAuthenticated(false); // Update state
    navigate('/login'); // Redirect to login page
  }
  return (
    // <div>
    //   <nav className="main-header navbar-fixed-top navbar navbar-expand-md navbar-light navbar-grey">

    //     <div class="container-fluid">
    //       <NavLink exact to="/dashboard" class="navbar-brand">
    //         <img src="/dist/img/neoVector_Logo_Dark.png" height="30" alt="NeoIndus Logo"></img> &nbsp;
    //         <span class="brand-text font-weight-bold">NEOVECTOR</span>
    //       </NavLink>
    //       <button
    //         class="navbar-toggler order-1"
    //         type="button"
    //         data-toggle="collapse"
    //         data-target="#navbarCollapse"
    //         aria-controls="navbarCollapse"
    //         aria-expanded="false"
    //         aria-label="Toggle navigation"
    //       >
    //         <span class="navbar-toggler-icon"></span>
    //       </button>
    //       <div class="collapse navbar-collapse order-3" id="navbarCollapse">
    //         <ul class="navbar-nav">
    //           <li class="nav-item">
    //             <NavLink exact to="/indus" className="nav-link">
    //               Dashboard
    //             </NavLink>
    //           </li>
    //           <li class="nav-item dropdown">
    //             <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Manage Farms</a>
    //             <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
    //               <li>
    //               <NavLink exact to="/farms" className="nav-link">
    //                 Batch History
    //               </NavLink>
    //               </li>
                  

    //               {/* <NavLink exact to="/" className="nav-link">
    //                 Farmers
    //               </NavLink> */}
    //             </ul>
    //           </li>
    //           {/* <li class="nav-item">
    //             <NavLink exact to="/indus" className="nav-link">
    //               Dashboard
    //             </NavLink>
    //           </li> */}
    //           {/* <li class="nav-item">
    //             <NavLink exact to="/dashboard" className="nav-link">
    //               Farm Conditions
    //             </NavLink>
    //           </li> */}
    //           <li class="nav-item dropdown">
    //             <NavLink exact to="/batchOperations" className="nav-link">
    //               Readings
    //             </NavLink>
    //           </li>
    //           <li class="nav-item dropdown">
    //             <NavLink exact to="/users" className="nav-link">
    //               App Users
    //             </NavLink>
    //           </li>
    //           <li className="nav-item dropdown">
    //             <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Device Management</a>
    //             <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
    //               <li>
    //                 <NavLink exact to="/deviceConnections" className="dropdown-item">
    //                   Connections
    //                 </NavLink>
    //               </li>
    //               <li>
    //                 <NavLink exact to="/deviceMaster" className="dropdown-item">
    //                   Device Master
    //                 </NavLink>
    //               </li>
    //               <li>
    //                 <NavLink exact to="/abnormalReadings" className="dropdown-item">
    //                   Abnormal Readings
    //                 </NavLink>
    //               </li>
    //               <li>
    //                 <NavLink exact to="/thresholdSettings" className="dropdown-item">
    //                   Threshold Settings
    //                 </NavLink>
    //               </li>
    //               <li>
    //                 <NavLink exact to="/analytics" className="dropdown-item">
    //                   Analytics
    //                 </NavLink>
    //               </li>
    //             </ul>
    //           </li>


    //         </ul>
    //       </div>
    //       <div class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
    //         <button type="button" class="btn btn-secondary btn-sm" style={{ fontSize: 12 }} onClick={() => { logout() }}>LOGOUT</button>
    //       </div>
    //       {/* <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            
    //         <li class="nav-item dropdown">
    //           <a class="nav-link" data-toggle="dropdown" href="#">
    //             <i class="far fa-bell"></i>
    //             <span class="badge badge-warning navbar-badge">15</span>
    //           </a>
    //           <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
    //             <span class="dropdown-header">15 Notifications</span>
    //             <div class="dropdown-divider"></div>
    //             <a href="#" class="dropdown-item">
    //               <i class="fas fa-envelope mr-2"></i> 4 new messages
    //               <span class="float-right text-muted text-sm">3 mins</span>
    //             </a>
    //             <div class="dropdown-divider"></div>
    //             <div class="dropdown-divider"></div>
    //             <a href="#" class="dropdown-item dropdown-footer">
    //               See All Notifications
    //             </a>
    //           </div>
    //         </li>
    //       </ul> */}
    //     </div>

    //   </nav>
    // </div>
    <Navbar bg="light" expand="md" className="navbar-fixed-top main-header navbar-fixed-top navbar-light navbar-grey">
      <div className="container-fluid d-flex justify-content-between align-items-center">
        {/* Left section: Logo and Brand Name */}
        <Navbar.Brand as={NavLink} to="/dashboard" className="d-flex align-items-center">
          <img src="./dist/img/neoVector_Logo_Dark.png" height="30" alt="NeoVector Logo" /> &nbsp;
          <span className="brand-text font-weight-bold">NEOVECTOR</span>
        </Navbar.Brand>


         {/* Navbar menu collapsible (center part) */}
      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={NavLink} to="/dashboard" style={{fontWeight:'bolder'}}>Dashboard</Nav.Link>
          {/* <NavDropdown title="Manage Farms" id="farms-dropdown">
            <NavDropdown.Item as={NavLink} to="/farms">Batch History</NavDropdown.Item>
          </NavDropdown> */}
          {/* <Nav.Link as={NavLink} to="/dashboard">Farm Conditions</Nav.Link> */}
          {/* <NavDropdown title="Manage Readings" id="farms-dropdown"> */}
          {/* <Nav.Link as={NavLink} to="/batchOperations">Readings</Nav.Link> */}
          <Nav.Link as={NavLink} to="/readingsByDate" style={{fontWeight:'bolder'}}>Readings</Nav.Link>
          {/* </NavDropdown> */}
          
          <Nav.Link as={NavLink} to="/users" style={{fontWeight:'bolder'}}>App Users</Nav.Link>

          <NavDropdown title="Device Management" id="device-management-dropdown" style={{fontWeight:'bolder'}}>
            {/* <NavDropdown.Item as={NavLink} to="/deviceConnections">Connections</NavDropdown.Item> */}
            <NavDropdown.Item as={NavLink} to="/deviceMaster" >Device Master</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/abnormalReadings" >Abnormal Readings</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/thresholdSettings" >Threshold Settings</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/analytics" >Analytics</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>

        {/* Right section: Logout button */}
        <div className="ml-auto">
          <button 
            type="button" 
            className="btn btn-secondary btn-sm" 
            style={{ fontSize: 12 }} 
            onClick={logout}
          >
            LOGOUT
          </button>
        </div>
      </div>

     
    </Navbar>
  );
};
export default TopHeader;
