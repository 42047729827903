import React from 'react';

const TestScreen = () => {
  return (
    <div>
      <h1>This is a Test Screen</h1>
      <p>Navigate here successfully after login.</p>
    </div>
  );
};

export default TestScreen;