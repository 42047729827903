import axios from "axios";
import React, { useState, useEffect } from "react";
import { env } from './const';
import { useLoading, Bars } from '@agney/react-loading';
import { headers } from '../utils/common';
import moment from "moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";

const BatchOperations = () => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />
    });
    const [headersobj] = useState(headers());
    const [showLoader, setShowLoader] = useState(false);
    const [farmerList, setFarmerList] = useState([]);
    const [farmsList, setFarmsList] = useState([]);
    const [devicesList, setDevicesList] = useState([]);
    const [selectedFarmer, setSelectedFarmer] = useState('');
    const [showTabs, setShowTabs] = useState(false);
    const [deviceID, setDeviceID] = useState('');
    const [uuid,setuuid] = useState('')
    const [showReadings, setShowReadings] = useState(false);
    const [value, onChange] = useState(new Date());
    const [deviceReadings, setDeviceReadings] = useState([]);
    const [progress, setProgress] = useState(true);

    const onUserChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        if (value === '') {
            alert('Please select Farm Owner');
            return;
        }
        setFarmsList([]);
        setDevicesList([]);
        setShowReadings(false);
        setSelectedFarmer(value);
        fetchFarms(value);
        setDeviceReadings([]);
    };

    const onFarmChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        if (value === '') {
            alert('Please select Farm');
            return;
        }
        setShowTabs(false);
        fetchDevices(value);
        console.log(`The FarmID is : ${value}}`)
        setDeviceReadings([]);
    };

    const onDeviceChange = (e) => {
        e.preventDefault();
        setDeviceReadings([]);
        const data = e.target.value;
        const [batchID, deviceID, deviceUUID] = data.split('$');
        setDeviceID(deviceID);
        setuuid(deviceUUID);//added uuid and device id
    };

    useEffect(() => {
        if (deviceID || uuid) {
            const currentDate = moment().format('YYYY-MM-DD');
            handleDateChange(currentDate, uuid);
        }
    }, [deviceID,uuid]);

    const handleDateChange = (date,uuid) => {
        const selectedDate = moment(date).format('YYYY-MM-DD');
        console.log(`the selectedDate is ${selectedDate}`);
        console.log(`the uuid is : ${uuid}`)
        setShowLoader(true);
        axios.get(`${env.produrl}/misc/deviceReadings/${uuid}/${selectedDate}`, { headers: headersobj })
            .then(res => {
                setShowLoader(false);
                if (!res.data.error) {
                    setDeviceReadings(res.data.data);
                    console.log(`the readings are : ${JSON.stringify(deviceReadings)}`);
                    setShowReadings(res.data.data.length > 0);
                } else {
                    alert('Error in fetching data');
                }
            }).catch((err) => {
                alert(err.message);
                setDeviceReadings([]);
                setShowReadings(false);
                setShowLoader(false);
            });
    };

    const fetchFarmers = () => {
        setShowLoader(true);
        axios.get(`${env.produrl}/misc/activeFarmersList/`, { headers: headersobj })
            .then(res => {
                setShowLoader(false);
                if (!res.data.error) {
                    setFarmerList(res.data.data);
                } else {
                    alert('Error in fetching data');
                }
            }).catch((err) => {
                alert(err.message);
                setShowLoader(false);
            });
    };

    const fetchFarms = (userID) => {
        setShowLoader(true);
        axios.get(`${env.produrl}/misc/listoffarmsAdmin/${userID}`, { headers: headersobj })
            .then(res => {
                setShowLoader(false);
                if (!res.data.error) {
                    setFarmsList(res.data.data);
                    if (res.data.data.length === 0) {
                        alert('No farms found for the selected farmer');
                    }
                } else {
                    alert('Error in fetching data');
                }
            }).catch((err) => {
                alert(err.message);
                setShowLoader(false);
            });
    };

    const fetchDevices = (farmID) => {
        setShowLoader(true);
        axios.get(`${env.produrl}/misc/listofDevicesByFarm/${farmID}`, { headers: headersobj })
            .then(res => {
                setShowLoader(false);
                if (!res.data.error) {
                    setDevicesList(res.data.data);
                    if (res.data.data.length === 0) {
                        alert('No devices found this farm');
                    }
                } else {
                    alert('Error in fetching data');
                }
            }).catch((err) => {
                alert(err.message);
                setShowLoader(false);
            });
    };

    const columns = [
        { name: "Date Time", selector: "createdDate", sortable: true, width: '120px', cell: row => <div>{moment(row.createdDate).format('HH:mm A')}</div> },
        { name: "Temperature", selector: "soilTemperature", sortable: true, width: '150px', cell: row => <div>{row.temp}</div> },
        { name: "Ammonia", selector: "soilMoisture", width: '150px', sortable: true, cell: row => <div>{row.nh}</div> },
        { name: "Heat Index", selector: "soilNitrogen", width: '150px', sortable: true, cell: row => <div>{row.heatIndex}</div> },
        { name: "Relative Humidity", selector: "soilPhosphorous", sortable: true, width: '150px', cell: row => <div>{row.rh}</div> },
        { name: "Light", selector: "soilPotassium", width: '150px', sortable: true, cell: row => <div>{row.light}</div> },
        { name: "Light Exposed per Day(Minutes)", selector: "soilPH", width: '150px', sortable: true, cell: row => <div>{row.lightExposedMinutesPerDay}</div> },
        // { name: "Soil EC", selector: "soilEC", width: '150px', sortable: true, cell: row => <div>{row.soilEC}</div> },
        // { name: "Air Temperature", selector: "airTemperature", width: '150px', sortable: true, cell: row => <div>{row.airTemperature}</div> },
        { name: "CO2", selector: "co2", sortable: true, cell: row => <div>{row.co}</div> }
    ];

    useEffect(() => {
        fetchFarmers();
    }, []);

    return (
        <div className="BatchOperations">
            <div className="row">
                <div className="col-md-12">
                    <h3>Readings</h3>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Select Farm Owner</label>
                                        <select className="form-control select2 input-sm" onChange={onUserChange} style={{ width: "100%" }}>
                                            <option>-Select-</option>
                                            {farmerList.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label>Select Farm</label>
                                    <select onChange={onFarmChange} className="form-control select2" style={{ width: "100%" }}>
                                        <option>-Select-</option>
                                        {farmsList.map(item => (
                                            <option key={item.barnID} value={item.barnID}>{item.barnName}</option>

                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label>Batch/Installed Devices</label>
                                    <select onChange={onDeviceChange} className="form-control select2" style={{ width: "100%" }}>
                                        <option>-Select-</option>
                                        {devicesList.map(item => (
                                            <option key={item.batchID} value={item.batchID + '$' + item.deviceID + '$' + item.deviceUUID}>
                                                {item.batchName + ' - ' + item.deviceName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label>&nbsp;</label>
                                    <div className="dashboardLoader" style={{ marginTop: 10 }}>
                                        {showLoader ? (
                                            <section {...containerProps} style={{ marginTop: "30px" }}>
                                                {indicatorEl}
                                            </section>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="DeviceReadings">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h5>Calendar</h5>
                                        <Calendar onChange={onChange} value={value} onClickDay={(date) => handleDateChange(date,uuid)} />
                                        <CSVLink data={deviceReadings}>Export Data to CSV</CSVLink>
                                    </div>
                                    <div className="col-md-8">
                                        <h5>Device Readings</h5>
                                        {showReadings ? (
                                            <DataTable
                                                // progressPending={progress}
                                                progressPending={false}
                                                columns={columns}
                                                data={deviceReadings}
                                                defaultSortField="createdDate"
                                                defaultSortAsc={false}
                                                pagination
                                                dense={false}
                                                highlightOnHover
                                                striped
                                                paginationPerPage={15}
                                                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                            />
                                        ) : (
                                            <p>No device readings available for the selected date.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BatchOperations;
